import { remove as removeDiacritics } from "diacritics";
import { flow } from "lodash";
import * as TABLES from "../../../constants/databaseTables";
import { MINIMUM_SEARCH_STRING_LENGTH } from "../../../constants/dbQuery";

const getWorkerAttributes = async (db, client, attributeName) => {
  try {
    const doc = await db
      .collection(TABLES.CLIENTS)
      .doc(client)
      .collection(TABLES.WORKERS_ATTRIBUTES)
      .doc(attributeName)
      .get();

    return doc?.data()?.[attributeName] ?? [];
  } catch (err) {
    console.error(err?.message);
  }
  return [];
};

export const getDepartments = async (db, client) =>
  getWorkerAttributes(db, client, "departments");

const lowercase = str => str.toLowerCase();
export const formatQueryTag = flow(removeDiacritics, lowercase);
export const formatQueryTagIfString = tag =>
  typeof tag === "string" ? formatQueryTag(tag) : tag;

export const takeFirstNCharacters = limit => str => {
  if (str.length <= limit) {
    return str;
  }

  return str.substring(0, limit);
};

export const getFormattedUserTag = tag =>
  takeFirstNCharacters(MINIMUM_SEARCH_STRING_LENGTH)(
    formatQueryTag(tag)
  )?.replace(/\s/g, "*");
