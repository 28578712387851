export default {
  SORT_ASC: "asc",
  SORT_DESC: "desc",
  ROW_SELECT_SINGLE: "radio",
  ROW_SELECT_MULTIPLE: "checkbox",
  ROW_SELECT_DISABLED: "ROW_SELECT_DISABLED",
  CHECKBOX_STATUS_CHECKED: "checked",
  CHECKBOX_STATUS_INDETERMINATE: "indeterminate",
  CHECKBOX_STATUS_UNCHECKED: "unchecked",
  INDICATOR_POSITION_LEFT: "left",
  INDICATOR_POSITION_RIGHT: "right",
  TYPE_STRING: "string",
  TYPE_NUMBER: "number",
  TYPE_BOOLEAN: "bool",
  TYPE_DATE: "date",
  FILTERS_POSITION_INLINE: "inline",
  FILTERS_POSITION_TOP: "top",
  FILTERS_POSITION_BOTTOM: "bottom"
};
