import * as expand from "./expand";
import * as mutate from "./mutate";
import * as rows from "./rows";
import * as selection from "./selection";
import * as sort from "./sort";
import * as type from "./type";

export default {
  ...rows,
  ...selection,
  ...expand,
  ...mutate,
  ...sort,
  ...type
};
