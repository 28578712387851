import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import { useImageURL } from "../../hooks/useImageURL";
import CachedImage from "./CachedImage";
import defaultAvatar from "../../images/default-avatar.png";

type UserImageProps = {
  alt: string;
  style: CSSProperties;
  src: string;
  iconColor?: string;
};

const UserImage = ({ src, style, alt, iconColor }: UserImageProps) => {
  const { data: imageUrl } = useImageURL(src);

  return imageUrl ? (
    <CachedImage srcUrl={imageUrl} alt={alt} style={style} />
  ) : (
    <img src={defaultAvatar} alt={alt} style={style} color={iconColor} />
  );
};

export default UserImage;
