import { useDispatch } from "react-redux";
import { setPopupData, show } from "../reducers/slices/alertPopup";
import { getButtonListOpts } from "./alert/button";
import {
  IAlertOptions,
  IAlertParams,
  paramToOption,
  pickButtonParam
} from "./alert/options";

export interface ButtonOptions {
  visible?: boolean;
  text?: string;
  value?: any;
  className?: string | Array<string>;
  closeModal?: boolean;
}

export interface ButtonList {
  [buttonNamespace: string]: ButtonOptions | boolean;
}

const defaultButton: ButtonOptions = {
  visible: true,
  text: undefined,
  value: null,
  className: "",
  closeModal: true
};

const defaultCancelButton: ButtonOptions = Object.assign({}, defaultButton, {
  visible: false,
  text: "Cancel",
  value: null
});

const defaultConfirmButton: ButtonOptions = Object.assign({}, defaultButton, {
  text: "OK",
  value: true
});

export const defaultButtonList: ButtonList = {
  cancel: defaultCancelButton,
  confirm: defaultConfirmButton
};

const defaultOpts: IAlertOptions = {
  title: null,
  customComponent: null,
  text: null,
  icon: null,
  buttons: defaultButtonList,
  className: null,
  closeOnClickOutside: true,
  closeOnEsc: true,
  dangerMode: false,
  timer: null,
  callback: null
};

export const useRoosterAlert = () => {
  const dispatch = useDispatch();

  const alert = (...args: IAlertParams): Promise<any> => {
    return new Promise((resolve, reject) => {
      const callback = (e: any) => {
        resolve(e);
      };
      const finalOptions = <any>{ ...defaultOpts };

      args.forEach((param, index) => {
        const changes = paramToOption(param, index, args);
        Object.assign(finalOptions, changes);
      });

      const buttonParamList = pickButtonParam(finalOptions);
      finalOptions.buttons = getButtonListOpts(buttonParamList);

      finalOptions.callback = callback;

      dispatch(setPopupData(finalOptions));
      setTimeout(() => {
        dispatch(show());
      }, 100); // make it smooth
    });
  };

  return { alert };
};
