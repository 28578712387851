import { isPlainObject } from "lodash";

export interface ButtonOptions {
  visible?: boolean;
  text?: string;
  value?: any;
  className?: string | Array<string>;
  closeModal?: boolean;
}

export interface ButtonList {
  [buttonNamespace: string]: ButtonOptions | boolean;
}

export const CONFIRM_KEY = "confirm";
export const CANCEL_KEY = "cancel";

const defaultButton: ButtonOptions = {
  visible: true,
  text: undefined,
  value: null,
  className: "",
  closeModal: true
};

const defaultCancelButton: ButtonOptions = {
  ...defaultButton,
  visible: false,
  text: "Cancel",
  value: null
};

const defaultConfirmButton: ButtonOptions = {
  ...defaultButton,
  text: "OK",
  value: true
};

export const defaultButtonList: ButtonList = {
  cancel: defaultCancelButton,
  confirm: defaultConfirmButton
};

const getDefaultButton = (key: string): ButtonOptions => {
  switch (key) {
    case CONFIRM_KEY:
      return defaultConfirmButton;

    case CANCEL_KEY:
      return defaultCancelButton;

    default:
      const text = key.charAt(0).toUpperCase() + key.slice(1);

      return { ...defaultButton, text, value: key };
  }
};

const normalizeButton = (
  key: string,
  param: string | Object | boolean
): ButtonOptions => {
  const button: ButtonOptions = getDefaultButton(key);

  if (param === true) {
    return { ...button, visible: true };
  }

  if (typeof param === "string") {
    return { ...button, visible: true, text: param };
  }

  if (isPlainObject(param)) {
    return {
      visible: true,
      ...button,
      ...param
    };
  }

  return { ...button, visible: false };
};

const normalizeButtonListObj = (obj: any): ButtonList => {
  const buttons: ButtonList = {};

  for (const key of Object.keys(obj)) {
    const opts: any = obj[key];
    const button: ButtonOptions = normalizeButton(key, opts);
    buttons[key] = button;
  }

  if (!buttons.cancel) {
    buttons.cancel = defaultCancelButton;
  }

  return buttons;
};

const normalizeButtonArray = (arr: any[]): ButtonList => {
  const buttonListObj: ButtonList = {};

  switch (arr.length) {
    /* input: ["Accept"]
     * result: only set the confirm button text to "Accept"
     */
    case 1:
      buttonListObj[CANCEL_KEY] = { ...defaultCancelButton, visible: false };

      break;

    /* input: ["No", "Ok!"]
     * result: Set cancel button to "No", and confirm to "Ok!"
     */
    case 2:
      buttonListObj[CANCEL_KEY] = normalizeButton(CANCEL_KEY, arr[0]);
      buttonListObj[CONFIRM_KEY] = normalizeButton(CONFIRM_KEY, arr[1]);

      break;

    default:
      console.error(`Invalid number of 'buttons' in array (${arr.length}).
      If you want more than 2 buttons, you need to use an object!`);
  }

  return buttonListObj;
};

export const getButtonListOpts = (
  opts: string | Object | boolean
): ButtonList => {
  let buttonListObj: ButtonList = defaultButtonList;

  if (typeof opts === "string") {
    buttonListObj[CONFIRM_KEY] = normalizeButton(CONFIRM_KEY, opts);
  } else if (Array.isArray(opts)) {
    buttonListObj = normalizeButtonArray(opts);
  } else if (isPlainObject(opts)) {
    buttonListObj = normalizeButtonListObj(opts);
  } else if (opts === true) {
    buttonListObj = normalizeButtonArray([true, true]);
  } else if (opts === false) {
    buttonListObj = normalizeButtonArray([false, false]);
  } else if (opts === undefined) {
    buttonListObj = defaultButtonList;
  }

  return buttonListObj;
};
