import { useQuery } from "react-query";
import { QueryKey } from "../constants";
import { firebase } from "../index";

const getImageURL = (ref: string): Promise<string> =>
  firebase.storage.ref(ref).getDownloadURL();

export const useImageURL = (ref: string) => {
  return useQuery([QueryKey.IMAGE_REF, ref], () => getImageURL(ref), {
    cacheTime: Infinity,
    staleTime: Infinity
  });
};
