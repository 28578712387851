/* eslint-disable import/no-cycle */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Spinner, Toast } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { compose, pure } from "recompose";
import { get, post } from "../../utilities/restClient";
import { GCF } from "../../constants/dbQuery";
import { useRoosterAlert } from "../../hooks/useRoosterAlert";
import BootstrapTable from "../BootstrapTable";
import {
  avatarColumnFormatter,
  emailColumnFormatter,
  nameColumnFormatter,
  titleColumnFormatter
} from "./helpers/table";
import { IInterviewer } from "./types";
import { getFormattedUserTag } from "../../utilities/firestore/query/misc";
import { withFirebase } from "../Firebase";
import { withAuthorization, withEmailVerification } from "../Session";
import { Typography } from "@mui/material";

const defaultHeaderStyle = {
  textAlign: "center",
  backgroundColor: "#DEEBF5",
  color: "#1b74bc"
};

const inputBoxStyle = {
  padding: "5px",
  paddingLeft: "8px",
  borderRadius: "4px",
  borderColor: "lightgrey",
  borderWidth: "1px",
  borderStyle: "solid",
  fontSize: "16px",
  width: "380px",
  textOverflow: "ellipsis"
};

const InterviewerDashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const { alert } = useRoosterAlert();
  const [show, setShow] = useState(false);
  const [isCurrentUserWorkerState, setIsCurrentUserWorkerState] =
    useState(false);
  const [isLoadingCurrentUserWorkerState, setIsLoadingCurrentUserWorkerState] =
    useState(false);
  const authUser = useSelector((state: any) => state.sessionState.authUser);
  const clientId = authUser?.client;
  const userEmail = authUser?.email;
  const [searchState, setSearchState] = useState("");
  const [isSearchingState, setIsSearchingState] = useState(false);
  const [processingEmailState, setProcessingEmailState] = useState<string[]>(
    []
  );
  const [processingCopyState, setProcessingCopyState] = useState<string[]>([]);
  const [interviewersDataState, setInterviewersDataState] = useState<
    IInterviewer[]
  >([]);

  useEffect(() => {
    if (userEmail) {
      setIsLoadingCurrentUserWorkerState(true);
      get(`${GCF.BASE_URL}/interviewerPreferences/userstatus/${userEmail}`, {})
        .then(({ data }) => {
          if (data?.isWorker === true) {
            setIsCurrentUserWorkerState(true);
          } else {
            setIsCurrentUserWorkerState(false);
          }
          setIsLoadingCurrentUserWorkerState(false);
        })
        .catch(() => {
          setIsCurrentUserWorkerState(false);
          setIsLoadingCurrentUserWorkerState(false);
        });
    }
  }, [userEmail]);

  const searchInterviewers = (criteria: string) => {
    if (criteria.length > 2) {
      setIsSearchingState(true);
      get(
        `${
          GCF.BASE_URL
        }/interviewerPreferences/interviewers/${clientId}/${getFormattedUserTag(
          criteria.substring(0, 3)
        )}`,
        {}
      )
        .then(({ data }) => {
          const { data: users } = data;
          const exactMatchFound: IInterviewer[] = [];
          const filteredUsers: IInterviewer[] = users?.filter(
            (row: IInterviewer) => {
              const [searchStateLowerCaseWord1, searchStateLowerCaseWord2] =
                criteria.toLowerCase().split(" ");
              const firstNameLowerCase = row.firstName.toLowerCase();
              const lastNameLowerCase = row.lastName.toLowerCase();
              if (
                firstNameLowerCase === searchStateLowerCaseWord1 &&
                lastNameLowerCase === searchStateLowerCaseWord2
              ) {
                exactMatchFound.push(row);
                return true;
              }

              if (exactMatchFound.length === 0) {
                if (
                  firstNameLowerCase.includes(searchStateLowerCaseWord1) ||
                  lastNameLowerCase.includes(searchStateLowerCaseWord2) ||
                  firstNameLowerCase.includes(searchStateLowerCaseWord2) ||
                  lastNameLowerCase.includes(searchStateLowerCaseWord1)
                ) {
                  return true;
                }
              }
              return false;
            }
          );
          setIsSearchingState(false);
          if (exactMatchFound.length > 0) {
            setInterviewersDataState(exactMatchFound);
          } else {
            setInterviewersDataState(
              filteredUsers.sort((a, b) =>
                a.name
                  .toLocaleLowerCase()
                  .localeCompare(b?.name?.toLocaleLowerCase())
              )
            );
          }
        })
        .catch(() => {
          setIsSearchingState(false);
        });
    }
  };

  const debouncedSearch = React.useRef(
    debounce(async criteria => {
      searchInterviewers(criteria);
    }, 500)
  ).current;

  async function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchState(e.target.value);
    debouncedSearch(e.target.value);
  }

  const handleSendEmailAction = (workerId: string, email: string) => {
    setProcessingEmailState([...processingEmailState, workerId]);
    post(`${GCF.BASE_URL}/interviewerPreferences/email`, {
      clientId,
      workerId
    })
      .then(() => {
        alert({
          title: t("ROOSTER.INTERVIEWER-DASHBOARD.EMAIL-SENT", {
            defaultValue: "Email sent"
          }),
          dangerMode: false,
          customComponent: (
            <div>
              {t(
                "ROOSTER.INTERVIEWER-DASHBOARD.INTERVIEW-PREFERENCES-EMAIL-SENT-TO",
                {
                  defaultValue: "Interview Preferences email sent to"
                }
              )}{" "}
              : <b>{email}</b>
            </div>
          )
        });
        setProcessingEmailState(
          processingEmailState.filter(wId => wId !== workerId)
        );
      })
      .catch(() => {
        alert({
          title: t("ROOSTER.INTERVIEWER-DASHBOARD.EMAIL-NOT-SENT", {
            defaultValue: "Email not sent"
          }),
          dangerMode: true,
          customComponent: (
            <div>
              {t(
                "ROOSTER.INTERVIEWER-DASHBOARD.INTERVIEW-PREFERENCES-EMAIL-NOT-SENT-TO",
                {
                  defaultValue: "Interview Preferences email not sent to"
                }
              )}{" "}
              : <b>{email}</b>
            </div>
          )
        });
        setProcessingEmailState(
          processingEmailState.filter(wId => wId !== workerId)
        );
      });
  };

  const handleCopyLink = (workerId: string) => {
    setProcessingCopyState([workerId]);
    get(`${GCF.BASE_URL}/interviewerPreferences/preference-link`, {
      params: { workerId }
    })
      .then(({ data }) => {
        if (data?.shortLink) {
          navigator.clipboard.writeText(data.shortLink).then(() => {
            setShow(true);
          });
        }
        setProcessingCopyState([]);
      })
      .catch(() => {
        setProcessingCopyState([]);
      });
  };

  const actionColumnFormatter = (cell: string, row: IInterviewer) => {
    const editLink = `/interviewer/preferences?c=${clientId}&wid=${row.key}&m=alias-mode`;
    return row.email ? (
      <div style={{ textAlign: "center" }}>
        <Button
          size="sm"
          onClick={() => {
            handleSendEmailAction(row.key, row.email);
          }}
          style={{
            color: "white"
          }}
        >
          {processingEmailState.includes(row.key) ? (
            <div className="spinner-border spinner-border-sm" role="status" />
          ) : (
            <FontAwesomeIcon icon="mail-bulk" />
          )}
          <span className="ml-1" />{" "}
          {t("ROOSTER.INTERVIEWER-DASHBOARD.EMAIL-LINK", {
            defaultValue: "Email Link"
          })}
        </Button>
        <span className="mr-2" />
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => handleCopyLink(row.key)}
        >
          {processingCopyState.includes(row.key) ? (
            <div className="spinner-border spinner-border-sm" role="status" />
          ) : (
            <FontAwesomeIcon icon="copy" />
          )}{" "}
          {t("ROOSTER.INTERVIEWER-DASHBOARD.COPY-LINK", {
            defaultValue: "Copy Link"
          })}
        </Button>
        <span className="mr-2" />
        <Link to={editLink} target="_blank">
          <Button size="sm" variant="outline-primary">
            <FontAwesomeIcon icon="cogs" />{" "}
            {t("ROOSTER.COMMON.EDIT", { defaultValue: "Edit" })}
          </Button>
        </Link>
      </div>
    ) : (
      <div style={{ color: "#707070", padding: "5px 10px" }}>
        (
        {t(
          "ROOSTER.INTERVIEWER-DASHBOARD.EMAIL-ADDRESS-IS-REQUIRED-FOR-PREFERENCES",
          { defaultValue: "An email address is required for preferences" }
        )}
        )
      </div>
    );
  };

  return (
    <>
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={6000}
        autohide
        style={{
          position: "fixed",
          bottom: "40px",
          right: "50%",
          transform: "translateX(+50%)"
        }}
      >
        <Toast.Header>
          <strong className="mr-auto">
            🔗{" "}
            {t("ROOSTER.INTERVIEWER-DASHBOARD.COPY-LINK-SMALL-CASE-TITLE", {
              defaultValue: "Copy link"
            })}
          </strong>
        </Toast.Header>
        <Toast.Body>
          {t("ROOSTER.INTERVIEWER-DASHBOARD.COPY-LINK-SMALL-CASE-BODY", {
            defaultValue:
              "Interviewer Preferences page link copied to the clipboard"
          })}
        </Toast.Body>
      </Toast>
      <div className="App">
        <header className="App-header">
          <div
            style={{
              padding: "20px",
              border: "#e0e0e0 solid 1px",
              borderRadius: ".5%",
              width: "90%"
            }}
          >
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <Typography variant="h4">
                      <FontAwesomeIcon fixedWidth icon="book-open" />
                      <span className="pr-1" />
                      {t("ROOSTER.TENANT-DATA-STYLES.INTERVIEWERS-LABEL", {
                        defaultValue: "Interviewers"
                      })}
                    </Typography>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        placeholder="Type at least 3 characters to search interviewers"
                        style={inputBoxStyle}
                        value={searchState}
                        onChange={handleSearchChange}
                      />
                      {isSearchingState && (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{ position: "relative", left: "-25px" }}
                        />
                      )}
                      <span className="mr-2" />
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => {
                          setInterviewersDataState([]);
                          setSearchState("");
                        }}
                      >
                        <FontAwesomeIcon icon="eraser" />{" "}
                        {t("ROOSTER.COMMON.CLEAR", {
                          defaultValue: "Clear"
                        })}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mt-3">
                      <BootstrapTable
                        keyField="key"
                        data={interviewersDataState}
                        columns={[
                          {
                            headerStyle: {
                              ...defaultHeaderStyle,
                              width: "4%"
                            },
                            text: "",
                            formatter: avatarColumnFormatter
                          },
                          {
                            headerStyle: {
                              ...defaultHeaderStyle,
                              width: "15%"
                            },
                            dataField: "name",
                            text: t("ROOSTER.COMMON.NAME", {
                              defaultValue: "Name"
                            }),
                            formatter: nameColumnFormatter,
                            sort: true
                          },
                          {
                            headerStyle: {
                              ...defaultHeaderStyle,
                              width: "25%"
                            },
                            dataField: "email",
                            text: t("ROOSTER.COMMON.EMAIL", {
                              defaultValue: "Email"
                            }),
                            formatter: emailColumnFormatter
                          },
                          {
                            headerStyle: {
                              ...defaultHeaderStyle
                            },
                            dataField: "title",
                            text: t("ROOSTER.COMMON.TITLE", {
                              defaultValue: "Title"
                            }),
                            formatter: titleColumnFormatter
                          },
                          {
                            headerStyle: {
                              ...defaultHeaderStyle,
                              width: "30%"
                            },
                            dataField: "actions",
                            text: t("ROOSTER.COMMON.ACTION", {
                              defaultValue: "Action"
                            }),
                            formatter: actionColumnFormatter
                          }
                        ]}
                        bootstrap4
                        hover={false}
                        striped
                        noDataIndication={() => {
                          return (
                            <div className="d-flex flex-row justify-content-center align-items-center">
                              <div className="mr-3">
                                <FontAwesomeIcon
                                  icon="search"
                                  size="2x"
                                  color="#d3d3d3"
                                />
                              </div>

                              <div>
                                {t(
                                  "ROOSTER.INTERVIEWER-DASHBOARD.BEGIN-SEARCH-INTERVIEWER",
                                  {
                                    defaultValue:
                                      "Enter interviewer's first or last name in the above box to begin search"
                                  }
                                )}
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default compose(
  pure,
  withRouter,
  withFirebase,
  withEmailVerification,
  withAuthorization()
)(InterviewerDashboardPage);
