import { isPlainObject } from "lodash";
import { ReactElement, ReactNode } from "react";
import { ButtonList } from "./button";

export interface IAlertOptions {
  title: string | null;
  text: string | null;
  customComponent: ReactNode | ReactElement | null;
  icon: string | null;
  buttons: ButtonList | Array<string | boolean> | boolean;
  className: string | null;
  closeOnClickOutside: boolean;
  closeOnEsc: boolean;
  dangerMode: boolean;
  timer: number | null;
  callback: Function | null;
  button?: string;
}

export type IAlertParams = (string | Partial<IAlertOptions>)[];

export const pickButtonParam = (opts: any): Object => {
  const singleButton: string | Object = opts && opts.button;
  const buttonList: Object = opts && opts.buttons;

  if (singleButton !== undefined && buttonList !== undefined) {
    console.error("Cannot set both 'button' and 'buttons' options!");
  }

  if (singleButton !== undefined) {
    return {
      confirm: singleButton
    };
  }
  return buttonList;
};

export const paramToOption = (
  param: any,
  index: number,
  allParams: IAlertParams
): any => {
  if (typeof param === "string") {
    if (index === 0) {
      // Example: swal("Hi there!");
      return {
        text: param
      };
    }
    if (index === 1) {
      return {
        text: param,
        title: allParams[0]
      };
    }
    if (index === 2) {
      // TODO : expectOptionsOrNothingAfter(index, allParams);

      return {
        icon: param
      };
    }
    // TODO invalidParam(param, index);
  }

  if (isPlainObject(param)) {
    // TODO : expectOptionsOrNothingAfter(index, allParams);
    return param;
  }

  return {};
};
