/* eslint react/prop-types: 0 */
import PropTypes from "prop-types";
import React, { Component } from "react";

import eventDelegater from "./cell-event-delegater";
import _ from "./utils";

class Cell extends eventDelegater(Component) {
  constructor(props) {
    super(props);
    this.createHandleEditingCell = this.createHandleEditingCell.bind(this);
  }

  createHandleEditingCell = originFunc => e => {
    const { onStart, rowIndex, columnIndex, clickToEdit, dbclickToEdit } =
      this.props;
    if ((clickToEdit || dbclickToEdit) && _.isFunction(originFunc)) {
      originFunc(e);
    }
    if (onStart) {
      onStart(rowIndex, columnIndex);
    }
  };

  render() {
    const {
      row,
      rowIndex,
      column,
      columnIndex,
      onStart,
      editable,
      clickToEdit,
      dbclickToEdit,
      ...rest
    } = this.props;
    const { dataField, formatter, formatExtraData } = column;
    const attrs = this.delegate({ ...rest });
    let content = column.isDummyField ? null : _.get(row, dataField);

    if (formatter) {
      content = column.formatter(content, row, rowIndex, formatExtraData);
    }

    if (clickToEdit && editable) {
      attrs.onClick = this.createHandleEditingCell(attrs.onClick);
    } else if (dbclickToEdit && editable) {
      attrs.onDoubleClick = this.createHandleEditingCell(attrs.onDoubleClick);
    }

    return (
      <td {...attrs}>
        {typeof content === "boolean" ? `${content}` : content}
      </td>
    );
  }
}

Cell.propTypes = {
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  column: PropTypes.object.isRequired,
  columnIndex: PropTypes.number.isRequired
};

export default Cell;
