import React, { CSSProperties } from "react";

type CachedImageProps = {
  srcUrl: string;
  alt: string;
  style: CSSProperties;
};

const CachedImage = ({ srcUrl, alt, style }: CachedImageProps) => {
  return <img src={srcUrl} alt={alt} style={style} />;
};

export default CachedImage;
