import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import UserImage from "../../LazyLoadImage/UserImage";
import { IInterviewer } from "../types";
import moment from "moment-timezone";

export const avatarColumnFormatter = (cell: string, row: IInterviewer) => {
  return (
    <div>
      <UserImage
        src={row.imageURL40x40}
        alt={row.firstName}
        style={{ borderRadius: "50px", height: "32px", width: "32px" }}
      />
    </div>
  );
};

export const nameColumnFormatter = (cell: string, row: IInterviewer) => {
  return (
    <div className="d-flex align-items-center">
      {row.firstName}
      <span className="mr-1" />
      {row.lastName}
      {row?.interviewerPreferenceStatus?.exist &&
        row?.interviewerPreferenceStatus?.userCreated && (
          <div
            title={`Interviewer Preferences Set ${
              row?.interviewerPreferenceStatus?.updatedAt &&
              `(Last Updated: ${moment(
                row?.interviewerPreferenceStatus?.updatedAt
              ).format("LLLL")})`
            }`}
          >
            <span className="mr-2" />
            <FontAwesomeIcon icon="book-open" color="#3674b7" />
          </div>
        )}
    </div>
  );
};

export const emailColumnFormatter = (cell: string, row: IInterviewer) => {
  return (
    <div>
      {row?.email ? (
        row.email
      ) : (
        <span style={{ fontWeight: 800, color: "#cb444a" }}>
          No email address
        </span>
      )}
    </div>
  );
};

export const titleColumnFormatter = (cell: string, row: IInterviewer) => {
  return <div>{row.title}</div>;
};
