import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";

const withAuthorization =
  (condition = authUser => !!authUser && authUser.client) =>
  Component => {
    const WithAuthorization = props => {
      const history = useHistory();
      const { authUser, userProcessing } = useSelector(
        state => state?.sessionState
      );

      useEffect(() => {
        const listener = props.firebase.onAuthUserListener(
          user => {
            if (!condition(user) && !userProcessing) {
              history.push(ROUTES.NOTAUTHORIZED);
            }
          },
          (routeToNavigate = ROUTES.HOME) => history.push(routeToNavigate),
          userProcessing
        );

        return () => listener();
      }, []);

      return condition(authUser) ? (
        <Component {...props} authUser={authUser} history={history} />
      ) : null;
    };

    return withFirebase(WithAuthorization);
  };

export default withAuthorization;
